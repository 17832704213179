@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes shine {
  0% {
    filter: brightness(1);
    transform: scale(1);
  }
  50% {
    filter: brightness(1.5);
    transform: scale(1.1);
  }
  100% {
    filter: brightness(1);
    transform: scale(1);
  }
}

.shine {
  animation: shine 2s ease-in-out forwards;
}

.hamburger-icon {
  position: fixed;
  top: 16px; /* Adjust as needed for padding from the top */
  right: 16px; /* Adjust as needed for padding from the right */
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  z-index: 50;
}

.hamburger-icon div {
  width: 100%;
  height: 2px;
  background-color: rgb(255 162 0);
  transition: all 0.5s ease-in-out;
}

.hamburger-icon.open div:nth-child(1) {
  transform: rotate(45deg) translate(0px, 2px);
}

.hamburger-icon.open div:nth-child(2) {
  transform: rotate(-45deg) translate(4px, -6px);
}

@keyframes overlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay {
  animation: overlayFadeIn 0.5s ease-in-out;
}

.rem-font {
  font-family: 'REM', sans-serif;
  font-family: 'REM', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 25px;
  text-transform: uppercase;
}

.nothing-you-could-do-regular {
  font-family: 'Nothing You Could Do', cursive;
}
